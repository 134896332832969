import getConfig from "next/config";

import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const { publicRuntimeConfig } = getConfig();

export const googleAnalyticsID = publicRuntimeConfig.GOOGLE_TRACKING_CODE;

export const category = {
  boilerConfirmation: "Boiler confirmation",
  calendar: "Repair calendar",
  checkout: "Repair checkout",
  confirmation: "Repair confirmation",
  details: "Repair details",
  financeLanding: "Finance Landing Page",
  homeCoverCheckoutPage: "Home Cover Checkout Page",
  homeCoverConfirmation: "Home Cover confirmation",
  homeCoverProductPage: "Home Cover Product Page",
  landing: "Repair landing",
  listGuideFAQ: "List Guide FAQ",
  listLanding: "List Landing Page",
  options: "Repair options",
  payment: "Repair payment",
  productDetailsPage: "Product Details Page",
  productLandingPage: "Product Listing Page",
  productTypePost: "Guide Category Post",
  raiseCallout: "Raise Call out",
  rentalOptions: "Rental options",
  repair: "Repair",
  reportIssueBookingPage: "Report Issue Booking Page",
  reportIssueConfirmation: "Report Issue Confirmation",
  reportIssueReview: "Report Issue Review Page",
  screener: "Repair screener",
  smartHomeLanding: "Smart Home Landing Page",
  solarConfirmationPage: "Solar Confirmation Page",
  solarLanding: "Solar Landing Page",
  solarOptionsPage: "Solar Options Page",
  subscriptionsLanding: "Subscriptions Landing Page",
};

export const screenerCategory = {
  [ScreenerVariant.BoilerRepair]: "Repair screener",
  [ScreenerVariant.Heatlink]: "Heatlink screener",
  [ScreenerVariant.HomeCover]: "Home Cover Screener",
  [ScreenerVariant.HomeServe]: "Home Cover Screener",
  [ScreenerVariant.BoilerSubscription]: "Boiler Subscription Screener",
};

export const actions = {
  back: "Back",
  bookCalloutVisit: "Book callout visit",
  bookRepair: "Book repair",
  bookVisit: "Book visit",
  changeMonth: "Change month",
  changePostcode: "Change postcode",
  chatToUS: "Chat to us",
  chooseDate: "Choose a date",
  close: "Close",
  customerAppDownload: "customer app download",
  edit: "Edit",
  faqCarousel: "FAQ carousel",
  faqTabs: "FAQ tabs",
  getFixedPrice: "Get your fixed price",
  home: "BOXT_home",
  homeCoverCallout: "HomeCover call out",
  howMuch: "How much will I be charged",
  installationCallout: "Installation call out",
  learnMore3DS: "Payment information",
  needNewBoiler: "Need a new boiler",
  next: "Next",
  openFaq: "Open Faq",
  openLivechat: "Open Livechat",
  postcode: "Postcode",
  postcodeFocus: "Postcode focus",
  postcodeGetStarted: "Postcode Get Started",
  preferredTime: "Preferred time",
  priceTabs: "Price tabs",
  readyToGetStarted: "Ready to get started",
  repairOptions: "3. Repair options",
  reportAnIssue: "Report an issue",
  selectDate: "Select date",
  start: "1.Start",
  startsRaisingIssue: "Starts raising issue",
  uploadPhotos: "Upload your photos",
  videoCarousel: "Video carousel",
  videoPlay: "Video play",
  whatIsIncluded: "What's included",
  whyReplace: "Why replace",
  youInSafeHandsCarousel: "You are in safe hands carousel",
  yourBoiler: "2.your boiler",
};

export const labels = {
  back: "Back",
  contact: "Contact",
  faq: "faq",
  footer: "Footer",
  hero: "Hero",
  inSafeHands: "Subscriptions - in safe hands",
  next: "Next",
  previous: "Previous",
  priceModule: "price module",
  rePressures: "Re-pressures",
  repairVisit: "Repair visit",
  repairVisitRepairRecommended: "Repair visit - repair recommended",
  repairVisitReplaceRecommended: "Repair visit - replace recommended",
  replaceBoilerRepairRecommended: "Replace your boiler - repair recommended",
  replaceBoilerReplaceRecommended: "Replace your boiler - replace recommended",
  replaceYourBoiler: "Replace your boiler",
  skip: "skip",
  sliderWithCTA: "List - slider with CTA",
  solarInSafeHands: "Solar - in safe hands",
  solarWhyDealWithStress: "Solar - why deal with stress",
  submitFindDetailsForm: "Submit form",
  whatIsIncluded: "What's included",
  whyDealWithStress: "Subscriptions - why deal with stress",
  whySection: "List - why section",
};
