import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import getConfig from "next/config";

import { useAnalytics } from "@Hooks/useAnalytics";

type Props = {
  children: ReactNode;
  path?: string;
};

const SegmentAnalyticsWrapper = ({ children, path }: Props) => {
  const prevPath = useRef<string | undefined>("");
  const segmentAnalytics = useAnalytics();
  useEffect(() => {
    const referrer =
      prevPath.current !== path ? `${getConfig().publicRuntimeConfig.CUSTOMER_SITE_URL}${prevPath.current}` : null;
    segmentAnalytics?.page({ path, referrer });
    prevPath.current = path;
  }, [path, prevPath, segmentAnalytics]);
  return <>{children}</>;
};

export default SegmentAnalyticsWrapper;
